//  The content of this file and it's variable are injected to less loader in "config-overrides.js" as part of FUS-730
//  in order to fix the issues when modules are lazy loaded. 
//  As a result changes to some values like "primary-color" will require dev server to be restarted for the changes to apply  
//  The reference link is https://3x.ant.design/docs/react/customize-theme#header


$primary-color: #ed1d25; // primary color for all components
$link-color: #ed1d25; // link color
$success-color: #52c41a; // success state color
$warning-color: #faad14; // warning state color
$error-color: #f5222d; // error state color

$color-white: #fff;
$color-gray91: #e8e8e8;
$color-light-red: #ffedeb;

$font-size-base: 14px; // major text font size

$heading-color: rgba(0, 0, 0, 0.85); // heading text color
$text-color: rgba(0, 0, 0, 0.65); // major text color
$text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
$disabled-color: rgba(0, 0, 0, 0.25); // disable state color

$border-radius-base: 4px; // major border radius
$border-color-base: #d9d9d9; // major border color

$box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // major shadow for layers
