.team-qualification-score-provided {
  &__form {
    .action-form__fields {
      padding-bottom: 8px;
    }

    .action-form__submit-buttons {
      margin-left: 0;
    }
  }
}
