.field-array-table {
  .ant-empty-normal {
    margin: 0;
  }

  .ant-table-small {
    overflow: hidden;
  }

  .ant-table-tbody {
    vertical-align: baseline;
  }

  .ant-table-row {
    vertical-align: top;

    .ant-row.ant-form-item {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
  .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th {
    padding: 10px 12px 11px 12px;

    &:first-child {
      padding-right: 0;
    }

    &:last-child {
      padding-left: 0;
    }
  }

  .ant-table-fixed-header .ant-table-scroll .ant-table-header {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .ant-table-header .ant-table-thead > tr > th:first-child .ant-table-header-column,
  .ant-table-body .ant-table-tbody > tr > td:first-child span {
    min-width: 18px;
    display: inline-block;
  }

  .ant-table-body .ant-table-tbody > tr > td:first-child span {
    line-height: 32px;
  }
}
