.action-form {
  &__fields {
    padding-bottom: 16px;
  }

  &__form-bottom-component {
    display: flex;
  }

  &__submit-buttons {
    margin-left: auto;
  }

  &__button {
    margin-right: 10px;
  }
}
