@import "../../styles/base/theme";

.registration-user-landing-page {
  &__flex-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 12px;
    justify-content: center;
  }

  &__content {
    width: 100%;
    padding: 24px 12px;

    // Max width for the container allows the banner image to be displayed at 825px x 210px. This width was chosen as
    // a sensible maximum width for the banner image and description content, to avoid these becoming too wide on large
    // screen sizes.
    max-width: 825px + 12px + 12px;

    .section-header-container {
      margin-bottom: 24px;
    }

    .text-editor {
      // Increase the font size for better readability.
      font-size: 15px;

      // Account for blank line that seems to always appear at the end of the content.
      margin-bottom: -37px;
    }
  }

  &__banner-container {
    height: 0;
    overflow: hidden;
    position: relative;
    margin-bottom: 40px;
    border-radius: 4px;

    // We want to achieve a 55 : 14 aspect ratio. See https://css-tricks.com/aspect-ratio-boxes/.
    padding-top: 210px / 825px * 100%;

    &__inside {
      background-color: rgb(250, 250, 250);
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__description {
    width: 100%;
  }

  &__bottom-section {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #e8e8e8;
    margin-top: 24px;
    padding-top: 40px;
  }
}
