.person-registration-competitions-team-item {
  border-bottom: 1px solid rgb(235, 237, 240);

  &__content {
    margin-bottom: 15px;
  }

  &__loading-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    // Match the height of a row when it's being rendered with typical content.
    height: 32px;
  }

  &__row {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
  }

  &__number {
    display: inline-block;
    min-width: 55px;
  }

  &__field-label {
    font-weight: bold;
    display: inline-block;
    // Match the Position label width so the values will be aligned.
    min-width: 60px;
    margin-right: 10px;
  }

  &__field-value {
    display: inline-block;
    margin-right: 24px;
  }

  &__row-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
  }

  &__form.ant-form {
    margin-top: 15px;

    .action-form__fields {
      display: flex;
      align-items: flex-start;
      padding: 0;
    }

    .ant-form-item {
      display: flex;
      align-items: flex-start;
      margin-right: 24px;
    }

    .ant-form-item-control-wrapper {
      width: 200px;
    }

    .ant-form-item-label,
    .ant-form-item-control {
      line-height: 32px;
    }

    .ant-form-item-with-help {
      margin-bottom: 0;
    }

    .ant-form-explain {
      margin-top: 2px;
    }

    .action-form__form-bottom-component {
      margin-left: auto;
    }
  }

  + .person-registration-competitions-team-item {
    margin-top: 24px;
  }
}
