.form-field-form-item--non-input {
  .ant-form-item-children {
    display: block;
  }

  .ant-alert {
    // Add margin so that the alert lines up with the form label (and is equal in height) when in a horizontal layout.
    margin-top: 4px;
    margin-bottom: 3px;
  }


}

.form-field-form-item--read-only {
  label {
    font-weight: bold;
  }
}

.form-field-form-item--edit-email-inputfield {
  .ant-form-item-control {
    display: block;
  }
  .ant-form-item-children {
    display: flex;

    input {
      width: 50%;
    }
  }
}

// Hide the TinyMCE v6 "Upgrade" Button in FormFieldContent/TextEditor form items.
.tox-promotion {
  display: none;
}