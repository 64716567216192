.login-form {
    
    //background-color: rgb(243, 243, 243);
    
    &__img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        object-fit: cover;
        width: 100%;
    }

    &__mode-toggle-btn {
        width: 50%;
        float: left;
        Button {
            border-radius: 0;
        }
    }

    &__main {
        display: block;
        margin: auto;
        width: 400px;
        box-shadow: 0 0 40px 4px #111118;
        border-radius: 5px;

        * {
            font-family: "Avenir Next", Avenir, -apple-system, BlinkMacSystemFont, Roboto !important;
        }
    }
  
    &__input-div {
        padding: 2em 2em 0 2em;
        margin-top: 3em;
        text-align: center;

        .ant-row {
            margin-bottom: 1em;
        }

        p {
            font-size: 1.2em;
        }
    }
    
    &__terms {
        padding: 1em 2em 1em 2em;
        background-color: rgb(238, 238, 238);
        text-rendering: optimizeLegibility;

        .ant-checkbox-wrapper {
            line-height: 1.5;
        }

        .ant-row {
            margin-bottom: 0;
        }
    }
    
    &__submit-btn {
        padding: 2em !important;
        line-height: 0% !important;
        font-size:x-large !important;
    }
  }