.my-registration-item {
  &__container {
    border: 1px solid rgb(217, 217, 217);
    border-radius: 4px;
    padding: 22px;
    max-width: 600px;
    min-width: 500px;
    margin-bottom: 22px;
    &--disabled{
      background-color: #fafafa;
    }
  }

  &__label {
    font-weight: bold;
    margin: 0px 5px;
    min-width: 45px;
  }

  &__button-container {
    margin-top: 22px;
  }
}