.section-header-container {
  display: block;
  width: 100%;
  border-bottom: 1px solid rgb(235, 237, 240);
  padding-bottom: 10px;
  margin-bottom: 15px;

  .ant-typography {
    margin-bottom: 0;
  }
}
