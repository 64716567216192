.registration-condition-list {
  &__container {
    display: block;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    width: 100%;
  }

  &__add-button-container {
    padding: 12px;
  }

  .ant-form-vertical & .registration-condition-list__container .ant-form-item {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .ant-form-item-control-wrapper {
    width: 100%;
  }

  .ant-form-item-control {
    line-height: 32px;
  }

  .ant-form-explain {
    margin-top: 3px;
  }

  .ant-form-item-control .ant-form-explain {
    margin-top: 1px;
  }
}
