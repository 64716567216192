@import "../styles/base/theme";

.app {
  background: $color-white !important;

  & header {
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    position: fixed;
    z-index: 10;
    width: 100%;
    padding: 0 24px;
    background-color: $color-white;
    border-bottom: 1px solid $color-gray91;
  }
}
