.form-field-elapsed-time {
  .ant-form-item-children {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  span.separator {
    margin-left: 3px;
    margin-right: 3px;
  }

  .ant-input-affix-wrapper {
    width: 80px;

    // Better vertical alignment with the prefix text.
    .ant-input {
      padding-top: 3px;
      padding-bottom: 4px;
      line-height: 22px;
    }

    .ant-input-prefix {
      color: #aaa;
    }
  }

  .form-field-elapsed-time__centiseconds.ant-input-affix-wrapper {
    .ant-input {
      // More spacing on the left as the prefix text is two characters instead of one.
      padding-left: 38px;
    }
  }

  .form-field-elapsed-time__input:last-of-type {
    margin-right: 9px;
  }

  // Show error icon to the right of the inputs and button.
  & .has-error.has-feedback .ant-form-item-children-icon {
    position: relative;
    top: auto;
    right: auto;
    margin-top: 0;
    height: 32px;
    line-height: 32px;
  }

  form & .has-feedback .ant-input {
    // Reset padding-right to normal amount, since the error icon is shown to the right of the input, not on top.
    padding-right: 11px;
  }

  // Vertically align the button icon with the button text.
  &__clear-btn.ant-btn {
    display: flex;
    flex-direction: row;
    align-items: center;

    .anticon {
      display: block;

      svg {
        display: block;
      }
    }

    span {
      line-height: 22px;
      margin-bottom: 1px;
    }
  }
}
