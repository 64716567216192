%overflow-x-scroll {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
}

%content-padding {
  padding: 24px;
}

.my-registrations-page {
  &__header-container {
    background-color: #fafafa;
    border-bottom: 1px solid #e8e8e8;
  }

  &__header {
    padding: 15px 24px 0 24px;
  }

  &__page-header.ant-page-header {
    margin-bottom: 14px;
  }

  &__container {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 130px);
    overflow: hidden;
  }

  &__aside {
    @extend %overflow-x-scroll;
    @extend %content-padding;
    flex: 0 0 20%;
    min-width: 240px;
  }

  &__content {
    @extend %overflow-x-scroll;
    @extend %content-padding;

    flex: 1;
  }
}
