.ant-calendar-picker.ant-input-group {
  &.calendar-picker--with-addon-after {
    .ant-calendar-picker-input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .ant-calendar-picker {
      display: table-cell;
    }
  }
}

form .has-feedback .ant-calendar-picker {
  &.calendar-picker--with-addon-after {
    .anticon {
      right: 12px;
    }
  }
}

.ant-input-group-wrapper.ant-input-group-wrapper--with-addon-after {
  width: auto;
}
