%overflow-x-scroll {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
}

%content-padding {
  padding: 24px 12px;
}

.person-detail-competitions {
  &__container {
    display: flex;
    height: calc(100% - 100px);
    overflow: hidden;
  }

  &__flex-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 12px;
  }

  &__content {
    @extend %overflow-x-scroll;
    @extend %content-padding;

    flex: 1;
  }

  &__left-sidebar {
    @extend %overflow-x-scroll;
    @extend %content-padding;

    flex: 0 0 20%;

    // Match the width of the Registrations tab left sidebar.
    min-width: 240px;
  }

  &__right-sidebar {
    @extend %overflow-x-scroll;
    @extend %content-padding;

    flex: 0 0 20%;

    // Match the width of the Registrations tab right sidebar.
    min-width: 240px;
  }
}
