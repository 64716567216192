.nav-header {
  &__home-link {
    height: 100%;
  }

  &__other {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-left: 15px;
  }
}
