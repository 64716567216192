%flex-shadow-full {
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6) !important;
  position: absolute !important;
  border-radius: 0 !important;
  width: 100% !important;
  height: 100% !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.avatar-upload {
  position: relative;
  overflow: hidden;

  &--circle {
    border-radius: 50%;
  }

  &:hover {
    .avatar-upload-upload {
      display: flex !important;
    }
  }

  .ant-spin-spinning {
    display: flex !important;
    @extend %flex-shadow-full;
  }

  &-upload {
    display: none !important;
    @extend %flex-shadow-full;
  }
}
