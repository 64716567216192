.form-field-checkbox-group {
  &__checkbox-group.ant-checkbox-group {
    border: 1px solid rgb(217, 217, 217);
    border-radius: 4px;
    padding: 0 12px;
    margin-top: 4px;
    width: 100%;
    max-height: 461px;
    overflow-y: auto;
  }

  &__checkbox-group--error.ant-checkbox-group {
    border: 1px solid rgb(245, 34, 45);
  }

  .ant-select-dropdown-menu-item-group-title {
    font-size: 14px;
    height: auto;
    line-height: inherit;
    padding-left: 0;
    margin-top: 5px;
  }
}

// Don't show status text colours for disabled checkbox labels.
.ant-checkbox-wrapper-disabled {
  .status-text {
    color: rgba(0, 0, 0, 0.25);
  }
}
