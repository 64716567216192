.status-text {
  &--default {
    color: rgba(0, 0, 0, 0.45);
  }

  &--warning {
    color: #faad14;
  }

  &--error {
    color: #f5222d;

    .status-text {
      color: #f5222d;
    }
  }
}
