.my-registrations-navigation {
  &__menu-item {
    height: auto !important;
    white-space: normal !important;
    line-height: 24px !important;
    background-color: transparent !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    overflow: visible !important;
  }

  &__menu-item-label-container {
    display: flex;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
