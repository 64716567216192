.react-phone-input.form-field-phone {
  .ant-form-item-children {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 396px;

    .ant-input {
      flex: 1;
    }
  }

  .ant-form-horizontal & {
    .flag-dropdown {
      top: 4px;
    }
  }

  .react-tel-input {
    padding-left: 48px;
  }

  .flag-dropdown {
    left: 0;
  }

  &--disabled .flag-dropdown {
    background-color: #f5f5f5;

    .selected-flag {
      cursor: not-allowed;
    }
  }

  & .has-error.has-feedback .ant-form-item-children-icon {
    right: 96px;
  }
}
