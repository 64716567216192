@import "../../styles/base/theme";

.header-search {
  display: flex;
  align-items: flex-end;
  height: 40px;
  width: 100%;
  max-width: 400px;

  &-tags {
    display: flex;
    position: relative;
    align-items: center;
    border: 1px solid $border-color-base;
    border-top-left-radius: $border-radius-base;
    border-bottom-left-radius: $border-radius-base;
    background-color: $color-white;
    padding: 6px 0 6px 11px;
    transition: all 0.3s;
    border-right: none;
    box-shadow: none;
    font-size: 16px;
    height: 40px;
    z-index: 2;
    order: 1;

    &:before {
      content: "";
      width: 2px;
      height: calc(100% + 2px);
      position: absolute;
      border-bottom: 1px solid $border-color-base;
      border-top: 1px solid $border-color-base;
      background-color: $color-white;
      transition: all 0.3s;
      right: -2px;
      top: -1px;
    }

    .ant-tag {
      margin-right: 0;

      & + .ant-tag {
        margin-left: 8px;
      }

      .anticon {
        vertical-align: -0.2em;
      }
    }
  }

  &-field {
    order: 2;

    &.--tags {
      .ant-input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
      }

      &.ant-select-focused + .header-search-tags {
        box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
      }

      &.ant-select-focused + .header-search-tags,
      &:hover + .header-search-tags {
        border-color: $primary-color;

        &:before {
          border-color: $primary-color;
        }
      }
    }
  }

  &-dropdown .ant-select-dropdown-menu {
    max-height: calc(100vh - 100px);
  }

  &-group-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    color: $disabled-color;
    min-width: 100%;
    cursor: pointer;
    border: none;
    padding: 0;

    span {
      color: $primary-color;
      font-weight: 600;
    }

    &:hover span {
      opacity: 0.5;
    }

    &.--disabled {
      cursor: default;

      span {
        color: $disabled-color;
      }

      &:hover span {
        opacity: 1;
      }
    }
  }
}
