.button-more.ant-btn {
  padding: 0 5px;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  color: #000;

  &:hover,
  &:focus {
    background-color: transparent;
    border-color: transparent;
  }
}
