.table-with-truncation {
  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
  }

  .ant-table-small > .ant-table-content .ant-table-header,
  .ant-table-small > .ant-table-content .ant-table-thead {
    background-color: #fafafa;
  }

  .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td {
    // Increase line-height and padding such that the height of the table with 1 row is equal to the height of the table
    // with no rows and with a custom `locale.emptyText` used.
    padding: 10px 12px 11px 12px;
    line-height: 32px;
  }

  .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
    // Match padding for table rows. Note that we are intentionally not matching the line height.
    padding: 10px 12px 11px 12px;
  }

  .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
  .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
    &.table-with-truncation__button-column {
      padding-left: 0;

      .ant-btn {
        display: block;
      }
    }

    &.table-with-truncation__index-column {
      padding-right: 0;
    }
  }
}
