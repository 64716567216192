.form-field-location {
  &__header-text {
    margin-bottom: 18px;
  }

  &__controls {
    display: flex;
  }

  &__select-wrapper {
    flex: 1;
    margin-right: 10px;
  }
}
