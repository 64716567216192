.reg-submission-progress {
  display: flex;
  flex-direction: row;
  align-items: center;
  &-chart {
    border-radius: 4px;
    max-width: 80px;

    .antd-pro-charts-pie-total {
      font-size: 14px;
      font-weight: bold;
      top: calc(50% - 1px);
      left: calc(50% + 1px);
    }
  }
  &-status {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
  }
}
