@import "../styles/base/theme";

.date-time-value-with-timezone {
  &__section {
    padding: 12px;
  }

  &__section + &__section {
    border-top: 1px solid $color-gray91;
  }
}
