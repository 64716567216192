// Don't show status text colours for disabled Select inputs, or disabled options within the Select dropdown.
.ant-select-dropdown-menu-item-disabled,
.ant-select-disabled {
  .status-text {
    color: rgba(0, 0, 0, 0.25);
  }
}

.form-field-select {
  &--with-select-all {
    .ant-select {
      width: calc(100% - 118px);
    }
  }

  &__select-all-button {
    min-width: 110px;
    margin-left: 8px;
  }

  .has-error.has-feedback &__select-all-button ~ .ant-form-item-children-icon {
    right: 118px;
  }
}
