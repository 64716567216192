.team-conditions-alert.ant-alert.ant-alert-info {
  background-color: #fff;
  border-color: #e8e8e8;
  margin-bottom: 24px;
  color: rgba(0,0,0,.85);

  &.team-conditions-alert--warning {
    border-color: #faad14;
  }

  &.team-conditions-alert--success {
    border-color: #52c41a;
  }

  .ant-alert-message {
    font-size: 14px;
  }

  .team-condition {
    margin-bottom: 5px;
    padding: 3px;
  }

  .team-condition--success {
    .anticon {
      color: #52c41a;
    }
  }

  .team-condition--warning {
    .anticon {
      color: #faad14;
    }
  }

  .team-condition--default {
    .anticon {
      color: rgba(0, 0, 0, 0.45);
    }
  }
}
