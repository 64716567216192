.header-user {
  &__authentication {
    margin-left: auto;
  }

  &__log-in {
    margin-right: 12px;
  }

  &__menu {
    min-width: 200px;

    &.ant-dropdown-menu ul,
    &.ant-dropdown-menu ol {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  &__avatar-container {
    display: flex;
    margin-left: 24px;
  }

  &__home-button-container {
    display: flex;
    margin-left: 12px;
  }
}
