.ant-form-vertical .registration-step-competitions-form-field-competitions {
  .competition-option {
    border: 1px solid rgb(217, 217, 217);
    border-radius: 4px;
    padding: 22px;
    width: 100%;

    &--error {
      border: 1px solid rgb(245, 34, 45);
    }
  }

  .competition-option + .competition-option {
    margin-top: 8px;
  }

  .competition-option__checkbox + .ant-form-item {
    margin-top: 20px;
  }

  .indent{
    margin-left: 20px;
    margin-top: 5px;
  }

  .ant-form-item {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .ant-form-item + .ant-form-item {
    margin-top: 18px;
  }

  .competition-name--read-only {
    margin-bottom: 0px;
  }

  .competition-option--items > div:first-of-type {
    margin-top: 10px;
  }
}
