@import "../base/theme";

.react-tel-input {
  font-family: inherit;
  font-size: inherit;

  .selected-flag {
    background-color: transparent;
  }

  .flag {
    background-color: $color-gray91;
  }

  .flag-dropdown {
    background-color: #fff;
    border: 1px solid $border-color-base;
    border-radius: $border-radius-base;
    height: 32px;

    &.open {
      background: transparent;
      border-radius: $border-radius-base;

      .selected-flag {
        background: transparent;
        border-radius: $border-radius-base;
      }
    }

    &:hover,
    &:focus {
      .selected-flag {
        background: transparent;
      }
    }
  }

  .country-list {
    box-shadow: none;
    border: 1px solid $border-color-base;
    border-radius: $border-radius-base;

    .country {
      padding: 0 9px;
      outline: none;
      line-height: 32px;
    }

    .country-name,
    .dial-code {
      font-family: inherit;
      font-size: inherit;
    }
  }
}
