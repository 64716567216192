.table-with-truncation-header {
  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .ant-table-column-title {
    display: block;
  }

  &--truncate .ant-table-column-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .report-table .ant-table-thead > tr > & {
    .ant-table-header-column {
      // It's tricky to get the right styling here to handle all the different use cases and to truncate a long column
      // title with an ellipsis. If things are not working correctly, see if there is a fix here:
      // https://medium.com/@gaurav5430/css-flex-positioning-gotchas-child-expands-to-more-than-the-width-allowed-by-the-parent-799c37428dd6
      display: block;
      max-width: 100%;
      flex-grow: 1;
      min-width: 0;

      .ant-table-column-sorters {
        display: flex;
        flex-direction: row;
        align-items: center;

        > .ant-table-column-title {
          display: block;
        }
      }
    }

    .ant-table-column-sorter {
      display: block;

      .ant-table-column-sorter-inner {
        height: 18px;
        margin: 0 0 0 10px;
      }
    }
  }
}
