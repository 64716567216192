.form-field-radio-group {
  &__radio-group.ant-radio-group {
    border: 1px solid rgb(217, 217, 217);
    border-radius: 4px;
    padding: 0 12px;
    margin-top: 4px;
    width: 100%;
    max-height: 461px;
    overflow-y: auto;
  }

  &__radio-group--error.ant-radio-group {
    border: 1px solid rgb(245, 34, 45);
  }

  .ant-select-dropdown-menu-item-group-title {
    font-size: 14px;
    height: auto;
    line-height: inherit;
    padding-left: 0;
    margin-top: 5px;
  }
  .ant-list-items .ant-list-item {
    border: none;
  }
}

// Don't show status text colours for disabled radio labels.
.ant-radio-wrapper-disabled {
  .status-text {
    color: rgba(0, 0, 0, 0.25);
  }
}
