.form-field-file-upload {
  // Show successful file uploads with green text, rather than using our primary colour (red), which may lead users to
  // think the upload has failed.
  .ant-upload-list-item-done a {
    color: #52c41a;
  }

  // Show failed file uploads with strong error styling to make it obvious that the upload failed.
  .ant-upload-list-item-error {
    background: #f5222d;
    color: #fff;

    &:hover .ant-upload-list-item-info {
      background: darken(#f5222d, 10%);
    }

    .anticon-paper-clip,
    .anticon-close,
    .ant-upload-list-item-name {
      color: #fff !important;
    }

    .ant-upload-list-item-name:before {
      content: "Error: ";
      font-weight: bold;
    }
  }

  .ant-upload.ant-upload-disabled .ant-btn {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    pointer-events: none;
  }

  &--uploading .ant-upload-list-item i {
    pointer-events: none;
  }
}
