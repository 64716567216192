.registration-status {
  margin-right: 8px;
  display: flex;
  align-items: center;

  .ant-avatar-sm {
    width: 20px;
    height: 20px;
    line-height: 20px;
  }

  .ant-avatar > .ant-avatar-string {
    font-size: 16px;
    font-weight: normal;
  }

  .ant-avatar-sm > .ant-avatar-string {
    font-size: 12px;
    font-weight: normal;
  }

  .ant-avatar {
    margin-right: 0 !important;
  }
}
