@import "../../styles/base/theme";

// TODO
.ant-drawer-content-wrapper {
  max-width: 90%;
}

.ant-radio-group + .ant-form-item-children-icon {
  right: -32px !important;
}
// End of TODO

.ant-form-item-control.has-error.has-feedback .ant-input-group-wrapper {
  // For cases where an input is part of an input group, show the error icon to the right of the input group. e.g. when
  // using `addonAfter` with the Input or InputNumber components. This is necessary because otherwise the error icon
  // appears on top of the `addonAfter` element.
  + .ant-form-item-children-icon {
    right: auto;
  }

  // Reset padding-right to normal amount, since the error icon is shown to the right of the input, not on top.
  .ant-input {
    padding-right: 11px;
  }
}

// Match styling of .ant-checkbox so that radio inputs are correctly vertically aligned with their label text.
.ant-radio {
  top: -0.09em;
}

// Page header - titles
.ant-page-header {
  & .ant-page-header-heading-title {
    font-size: 20px;
  }
  & .ant-page-header-heading-sub-title {
    font-size: 14px;
  }
}

// Horizontal forms
.ant-form-horizontal {
  & .ant-form-item-label {
    white-space: normal;
  }

  & .ant-form-item-label > label {
    display: inline-block;
    padding: 10px 8px 10px 0;
    line-height: normal;
    padding-right: 6px;
  }

  // Hide the colon after the label for horizontal forms.
  & .ant-form-item-label > label::after {
    content: none;
    margin: 0;
  }
}

// Widget Chart Pie
.antd-pro-charts-pie-legend .antd-pro-charts-pie-value {
  display: none;
}

// Table selected row
.ant-selected-table-row {
  background-color: $color-light-red;
}

// Table filters
.ant-table-thead > tr > th {
  .anticon-filter,
  .ant-table-filter-icon {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      position: static;
      margin-top: 0;
      margin-left: 0;
      display: block;
    }

    &.ant-table-filter-selected {
      background: $color-light-red;
      color: $primary-color;
    }
  }

  &.ant-table-column-has-actions.ant-table-column-has-sorters,
  &.ant-table-column-has-actions.ant-table-column-has-filters {
    .anticon-filter.ant-table-filter-open,
    .ant-table-filter-icon.ant-table-filter-open {
      background: $primary-color;
      color: #ffffff;
    }

    &:hover .anticon-filter,
    &:hover .ant-table-filter-icon {
      &:hover {
        background: $primary-color;
        color: #ffffff;
      }

      &.ant-table-filter-selected {
        background: $color-light-red;
        color: $primary-color;

        &:hover {
          background: $primary-color;
          color: #ffffff;
        }
      }

      &.ant-table-filter-open {
        background: $primary-color;
        color: #ffffff;
      }
    }
  }
}

.ant-dropdown-menu-dark {
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color: rgba(255, 255, 255, 0.9);

    > a {
      color: rgba(255, 255, 255, 0.9);
    }
  }

  .ant-dropdown-menu-item.ant-dropdown-menu-item-disabled {
    color: rgba(255, 255, 255, 0.65);

    > a {
      color: rgba(255, 255, 255, 0.65);
    }
  }
}

// Line up the list's bullet points horizontally with the title above
.ant-modal-confirm-content ul,
.ant-alert-description ul {
  padding-inline-start: 18px;
}

// This style is to keep the drawer header fixed and the body of the drawer scrolled
.ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;

  height: 100%;
  .ant-drawer-body {
    flex: 1 1 auto;
    overflow: auto;
  }
}

.ant-popover-inner-content {
  // Allow individual popovers to control their own padding.
  padding: 0;
}
