.form-field-team {
  width: 100%;

  &__container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
  }

  .ant-form-item,
  .ant-select {
    width: 100%;
  }

  &__button {
    margin-left: 8px;

    // Match height of input label so that alignment is correct.
    margin-top: 29px;
  }

  &__position {
    margin-top: 10px;
  }
}

.registration-step-competition-form-field-team {
  &__create-new {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
