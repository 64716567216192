.form-field-competition-teams {
  width: 100%;

  .header-text {
    margin-bottom: 18px;
  }

  &__add-button {
    margin-bottom: 12px;
  }
}
