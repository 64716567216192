@import "../base/theme";

.ant-dropdown-menu-item-group-list .ant-dropdown-menu-item {
  padding-left: 20px;
}

.ant-dropdown-menu-item-group-title {
  line-height: 22px;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 6px solid $primary-color !important;
}

.ant-drawer .ant-drawer-header {
  padding-top: 21px;
  padding-bottom: 20px;
  padding-right: 64px;
}

.ant-drawer-tabs.ant-drawer .ant-drawer-header {
  padding-top: 20px;
  padding-bottom: 0;
  padding-left: 0;
}

.ant-drawer .ant-drawer-close {
  width: 64px;
  height: 64px;
}

.ant-table-summary .ant-table {
  border: none;
}

.ant-table-summary .ant-table-thead {
  display: none;
}

.ant-table-summary .ant-table-tbody > tr > td {
  background: transparent !important;
}

.ant-table-summary tr {
  cursor: pointer;
}

.ant-table-pagination-wrap {
  &-center {
    justify-content: center;
  }

  &-right {
    justify-content: flex-end;
  }

  display: flex;
  align-items: center;
  width: 100%;
  min-height: 38px;
  max-height: 38px;
  border-top: 1px solid rgb(232, 232, 232);
  padding: 5px 15px;
  background-color: #fafafa;
  position: absolute;
  bottom: 0;
}

.ant-table-summary .ant-table-body {
  margin: 0 !important;
}

.ant-table-primary .ant-table {
  border: none;
}

.ant-table-primary .ant-table-body {
  margin: 0 !important;
}

.ant-table-primary .ant-table-pagination.ant-pagination {
  margin-right: 16px;
}

.ant-table-header-draggable {
  position: relative;
  cursor: grabbing;

  &-drag-icon {
    padding: 4px 12px;
    background-color: $border-color-base;
    border-radius: 4px;
    position: absolute;
    top: -50px;
  }

  .ant-table-header-dropzone {
    &-left {
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background-color: $primary-color;
      visibility: hidden;
    }

    &-right {
      position: absolute;
      top: 0;
      right: 0;
      width: 4px;
      height: 100%;
      background-color: $primary-color;
      visibility: hidden;
    }

    &-active {
      visibility: visible;
    }
  }
}

.pagination-one-page-total {
  margin: 16px 0;
  text-align: right;
}

.menu-admin-table-wrap,
.reg-table-wrap,
.form-table-wrap,
.person-table-wrap {
  height: 100%;
  flex: 1;
  position: relative;

  .ant-table-wrapper,
  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table,
  .ant-table-content,
  .ant-table-scroll,
  .ant-table-body {
    height: 100%;
  }

  .ant-table-empty .ant-table-body {
    height: auto;
  }

  .ant-table {
    border: none;
    border-radius: 0;
  }

  .ant-table-header table {
    border-radius: 0;
  }

  .ant-table-thead > tr:first-child {
    > th:first-child {
      border-top-left-radius: 0;
    }

    > th:last-child {
      border-top-right-radius: 0;
    }
  }

  .ant-table > .ant-table-content .ant-table-header,
  .ant-table > .ant-table-content .ant-table-thead {
    background-color: #fafafa;

    > table > .ant-table-thead > tr > th {
      background-color: #fff;

      &:last-child {
        border-right: none !important;
      }
    }
  }

  .ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar {
    border: 1px solid $color-gray91 !important;
    border-top: none !important;
    background-color: transparent;
    scrollbar-color: auto auto;
  }

  .ant-table-row {
    cursor: pointer;
  }
}

.menu-admin-table-wrap {
  &.menu-admin-table-wrap--has-list-context {
    border-right: 1px solid $color-gray91;
    height: 100%;
    background: #fafafa;
  }
}

.menu-admin-table-wrap,
.reg-table-wrap,
.form-table-wrap {
  .ant-table
    > .ant-table-content
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr
    > td:first-child:not(.ant-table-selection-column),
  .ant-table
    > .ant-table-content
    > .ant-table-body
    > table
    > .ant-table-thead
    > tr
    > th:first-child:not(.ant-table-selection-column),
  .ant-table
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr
    > td:first-child:not(.ant-table-selection-column),
  .ant-table
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th:first-child:not(.ant-table-selection-column) {
    padding-left: 24px;
  }

  .ant-table > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td:last-child,
  .ant-table > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th:last-child,
  .ant-table > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td:last-child,
  .ant-table
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th:last-child {
    padding-right: 24px;
  }
}

.person-table-wrap {
  .ant-table-thead > tr > th.ant-resizable-wrap {
    padding: 0 !important;

    .person-table-header-cell__resizable-children-container {
      border-right: 1px solid $color-gray91;
      padding: 8px;
      height: 100%;
      width: 100%;
    }

    &.ant-table-column-has-actions.ant-table-column-has-filters
      .person-table-header-cell__resizable-children-container {
      padding-right: 30px !important;
    }
  }

  tbody > tr > td {
    transition-duration: unset !important;
  }
}

.person-column-header--fixed__resizable {
  position: absolute !important;
  height: 100% !important;
  top: 0;

  > div {
    display: flex;
    align-items: center;
  }
}

.person-dynamic-column-header {
  .ant-table-header-column {
    height: 21px;
    max-width: 100%;
  }

  span.ant-table-column-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 144px;
    max-width: 100%;
    display: inline-block;
  }

  &.ant-table-column-has-filters span.ant-table-column-title {
    min-width: 122px;
  }

  &--last-column {
    span.ant-table-column-title {
      max-width: 144px;
    }

    &.ant-table-column-has-filters span.ant-table-column-title {
      max-width: 122px;
    }
  }
}

.person-table-wrap th.ant-table-header-draggable:last-child:not(.col-name) {
  span.ant-table-column-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 144px;
    max-width: 144px;
    display: block;
  }
}

.person-detail-main {
  height: 100%;
}

.person-detail-main .ant-menu .ant-menu-item .menu-item-badge {
  display: inline-block;
  background-color: $color-white;
  box-shadow: 0 0 0 1px $border-color-base inset;
  border-radius: 10px;
  font-weight: normal;
  white-space: nowrap;
  text-align: center;
  line-height: 20px;
  margin-left: 8px;
  min-width: 20px;
  font-size: 12px;
  padding: 0 6px;
  z-index: auto;
  color: $text-color;
  height: 20px;
}

.person-detail-main .ant-menu .ant-menu-item .menu-item-badge:empty {
  display: none;
}

.person-detail-main .ant-menu .ant-menu-item.ant-menu-item-active .menu-item-badge,
.person-detail-main .ant-menu .ant-menu-item.ant-menu-item-selected .menu-item-badge {
  box-shadow: 0 0 0 1px $primary-color inset;
  color: $primary-color;
}

.person-view-menu {
  max-width: 350px;
}

.person-view-menu .ant-dropdown-menu-item-divider {
  margin: 0;
}

.person-view-menu .person-view-no-views {
  line-height: 22px;
  padding: 5px 12px 5px 24px;
}

.person-view-menu .person-view-group ul {
  padding: 0;
  max-height: 160px;
  overflow-y: auto;
}

.person-view-menu .person-view-group ul li {
  padding-left: 24px;
  list-style-type: none;
}

.person-layout-page-header .ant-page-header-heading-sub-title {
  font-size: 16px;
  padding-left: 0;
}

.person-reg-list .ant-select-selection__placeholder {
  color: rgba(0, 0, 0, 0.65);
}

.person-reg-list {
  .ant-select-selection__clear {
    opacity: 1;
  }
}

.ant-menu .ant-menu-item:not(.ant-menu-item-selected):hover {
  border-bottom-color: transparent !important;
}

.table-date {
  display: inline-block;
  min-width: 90px;

  // Change vertical alignment to try to match alignment of other cells.
  vertical-align: bottom;
}

.table-date span {
  overflow-wrap: unset !important;
  word-break: unset !important;
}

.reg-step-remove-icon.disabled {
  pointer-events: none;
  cursor: inherit;
}

.form-field-spin {
  margin-left: 3px !important;
  margin-top: 1px !important;

  .ant-form-vertical & {
    height: 32px;
    display: flex;
    align-items: center;
    margin-top: 0 !important;
  }
}

.form-field-alert {
  padding: 4px 11px !important;
  display: flex;
  align-items: center;
}

.form-field-alert .ant-alert-message {
  line-height: 22px;
  color: rgba(0, 0, 0, 0.65);
}

.ant-upload-list .ant-upload-list-item:last-child {
  margin-bottom: 8px;
}

.form-field-checkbox-group .ant-form-item-control {
  line-height: inherit;
}

.ant-form-item-control .ant-input-number {
  width: 100%;
}

.form-field-condition-wrap .ant-input-number,
.form-field-condition-wrap .ant-time-picker {
  width: 100%;
}

.form-field-condition-wrap .ant-calendar-picker {
  min-width: auto !important;
  width: 100%;
}

.form-builder-modal-ul {
  margin: 10px 0;
  padding-left: 25px;
}

.copy-to-clipboard i {
  cursor: pointer;
  margin-left: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.text-with-tooltip {
  text-decoration: underline dashed #cdcdcd;
  text-underline-offset: 3px;
}
